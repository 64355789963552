<template>
  <v-container fluid>
    <v-dialog v-model="this.$store.getters['Auth/is_doc_open']" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="colll" cols="12" sm="12" md="12">
                <v-text-field v-model="doc.object" label="Object"></v-text-field>
                <date-picker
                  v-model="doc.publi_date"
                  value-type="format"
                  format="YYYY-MM-DD hh:mm:ss"
                  type="datetime"
                  placeholder="Date de Publication"
                ></date-picker>
              </v-col>
              <v-col class="colll" cols="12" sm="12" md="12">
                <date-picker
                  v-model="doc.deadline"
                  value-type="format"
                  format="YYYY-MM-DD hh:mm:ss"
                  type="datetime"
                  placeholder="Deadline"
                ></date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  :items="this.$store.getters['Auth/get_all_legal_form'].document_types"
                  v-model="doc.type"
                  label="Le type de document"
                  item-text="description"
                  item-value="status"
                ></v-select>
                <v-switch
                  v-if="doc.type != 3"
                  v-model="doc.free"
                  :label="`Tarification du Document: ${!doc.free ? 'Non Gratuit' : 'Gratuit'}`"
                ></v-switch>
                <v-select
                  :items="this.$store.getters['Auth/getListAvis']"
                  v-model="doc.notice"
                  label="L'avis consernée"
                  item-text="object"
                  item-value="id"
                ></v-select>
                <v-file-input
                  v-model="doc.file"
                  placeholder="Charger le fichier"
                  label="Fichier Avis"
                  multiple
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close_doc">Annuler</v-btn>
          <v-btn
            color="green darken-1"
            :loading="this.$store.getters['Auth/loadDoc']"
            text
            @click="addthisNotice"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      locale="fr"
      :headers="headers"
      :search="search"
      @click:row="goto"
      no-data-text="Aucune demande disponible"
      :items="this.$store.getters['Auth/getListRequest']"
      sort-by="createdAt"
      class="elevation-1 mb-5 px-5"
    >
      <template v-slot:[`item.is_free`]="{ item }">
        <v-chip label small color="primary">
          {{ `${item.free ? "Gratuit" : "Payant"}` }}
        </v-chip>
      </template>
      <template v-slot:[`item.deadline`]="{ item }">
        {{ item.deadline | moment("dddd, Do MMMM YYYY") }}
      </template>
      <template v-slot:[`item.publiDate`]="{ item }">
        {{ item.publiDate | moment("dddd, Do MMMM YYYY") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">Historique des demandes d’éclaircissements</v-toolbar-title>
          <v-divider class="mx-4" inset vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherchez une demande d’éclaircissements"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss") }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  data: () => ({
    search: "",
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Demandes",
        disabled: true
      }
    ],
    dog: true,
    noticeType: [],
    doc: {
      object: "",
      publi_date: "",
      deadline: "",
      type: [],
      notice: [],
      free: true,
      file: []
    },
    dialog: false,
    headers: [
      {
        text: "Objet de la demande d’éclaircissements",
        align: "start",
        sortable: true,
        value: "object"
      },
      {
        text: "Type",
        sortable: true,
        value: "requestType.title"
      },
      {
        text: "Dossier concerné par la demande d’éclaircissements ",
        sortable: true,
        value: "document.object"
      },
      { 
        text: "Date/heure de la demande d’éclaircissements",
        value: "createdAt",
        sortable: true
      }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: "",
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau Document" : "Création du Plan de Passation des Marchés";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllRequest");
    this.$store.dispatch("Auth/loadAllDoc");
  },
  methods: {
    ...mapActions({
      addDoc: "Auth/addDoc",
      loadAllNotice: "Auth/loadAllNotice"
    }),
    open_doc() {
      this.$store.commit("Auth/OPEN_DOC", true);
    },
    close_doc() {
      this.$store.commit("Auth/OPEN_DOC", false);
    },
    /*     goto(val) {
      this.$router.push({ name: "showRequest", params: { id: val } });
    },
    seereply(val) {
      this.$router.push({ name: "showRequestResponse", params: { id: val } });
    }, */
    addthisNotice() {
      const data = new FormData();
      data.append("object", this.doc.object);
      data.append("doc_type", this.doc.type);
      data.append("notice_id", this.doc.notice);
      data.append("is_free", this.doc.free);
      data.append("publi_date", this.doc.publi_date);
      data.append("deadline", this.doc.deadline);
      if (this.doc.file) {
        data.append("file", this.doc.file[0]);
      } else {
        data.append("file", null);
      }
      // data.append("file", this.doc.file[0]);
      // console.log(this.notice);
      this.addDoc(data);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    getPPM(item) {
      console.log(item);
      this.$router.push({ name: "user", params: { ppm: item } });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    goto(item) {
      this.$router.push({ name: "showRequest", params: { id: item.id } });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
<style scoped>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
