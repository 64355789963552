<template>
  <v-container fluid>
    {{this.$store.getters['Auth/get_selected_request_response']}}
<!--     <v-row v-if="this.$store.getters['Auth/get_selected_request_response']">
      <v-col cols="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Informations</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Object :</b>
            {{ this.$store.getters["Auth/get_selected_request_response"].object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Contenu :</b>
            {{ this.$store.getters["Auth/get_selected_request_response"].content }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date de création :</b>
            {{ this.$store.getters["Auth/get_selected_request_response"].createdAt }}
            <v-spacer class="my-1"></v-spacer>
            <b>Types de demandes :</b>
            {{
              this.$store.getters["Auth/get_selected_request_response"].request.requestType
                .description
            }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card style="height:62em">
          <v-card-title>
            <h4>Aperçu document actuel</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <VueDocPreview
                v-if="
                  this.$store.getters['Auth/get_selected_request_response'].fileType === 'docx' ||
                    this.$store.getters['Auth/get_selected_request_response'].fileType === 'doc' ||
                    this.$store.getters['Auth/get_selected_request_response'].fileType === 'xsl'
                "
                style="height:62em;width:100%"
                frameborder="0"
                :value="this.$store.getters['Auth/get_selected_request_response'].fileLink"
                type="office"
              />

              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_request_response'].fileType === 'pdf'"
                :src="this.$store.getters['Auth/get_selected_request_response'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import VueDocPreview from "vue-doc-preview";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/getRequestResponse", this.id);
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
