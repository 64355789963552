<template>
  <v-container fluid>
    <v-row>
      <v-row justify="center" v-if="dialog">
        <v-dialog eager v-model="dialog" width="900px">
          <v-card>
            <v-card-title>
              <h4>
                Reponse de la
                {{ this.$store.getters["Auth/get_selected_request"].requestType.description }}
              </h4>
              <v-spacer></v-spacer>
              <h6>
                Date :
                {{
                  this.$store.getters["Auth/get_selected_ppm_rev"].publishDate
                    | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                }}
              </h6>
            </v-card-title>
            <v-divider></v-divider>
            <div style="padding:5px;padding-left:25px;padding-bottom:5px">
              <b>Description :</b>
              {{ this.$store.getters["Auth/get_selected_ppm_rev"].description }}
            </div>
            <v-divider></v-divider>
            <VueDocPreview
              v-if="
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
              type="office"
            />
            <v-card-text>
              <v-row>
                <vue-friendly-iframe
                  v-if="this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'pdf'"
                  allow="true"
                  :src="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
                  class="w-62em"
                  frameborder="0"
                  loading="lazy"
                ></vue-friendly-iframe>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              <!--Résumé des informations sur la demande d’éclaircissements-->

              {{ this.$store.getters["Auth/get_selected_request"].requestType.description }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            {{ this.Virt }}
            <b>Objet de la demande d’éclaircissements :</b>
            {{ this.$store.getters["Auth/get_selected_request"].object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Description sommaire de la demande d’éclaircissements :</b>
            {{ this.$store.getters["Auth/get_selected_request"].content }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de transmission de la demande d’éclaircissements :</b>
            {{
              this.$store.getters["Auth/get_selected_request"].createdAt
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Dossier concerné par la demande d’éclaircissements :</b>
            {{ this.$store.getters["Auth/get_selected_request"].document.object }}
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card class="pa-2 mt-4">
            <v-card-text v-if="this.$store.getters['Auth/get_selected_request'].responseList">
              <v-row>
                <v-list-item
                  class="block text-truncate"
                  v-for="item in this.$store.getters['Auth/get_selected_request'].responseList"
                  :key="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text text-truncate font-weight-bold"
                      v-text="item.object"
                    ></v-list-item-title>
                    <v-list-item-subtitle class="secondary--text">
                      {{ item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon @click="showResponse(item.id)" color="grey lighten-1">mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card
          v-if="
            profile == 3 && !this.$store.getters['Auth/get_selected_request'].responseList.length
          "
          class="mt-4"
        >
          <v-card-title>
            <h4>Répondre à la demande d’éclaircissements</h4>
          </v-card-title>
          <v-card-text>
            <v-text-field 
              v-model="response.objet" 
              label="Objet de la réponse à la demande d’éclaircissements">
            </v-text-field>
            <v-textarea
              name="input-7-1"
              v-model="response.content"
              label="Contenu de la réponse à la demande d’éclaircissements"
              value="Décrivez le fichier"
            ></v-textarea>

            <v-file-input
              placeholder="Charger le fichier de la demande"
              label="Joindre le courrier/la lettre de la réponse à la demande d’éclaircissements"
              multiple
              v-model="response.file"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="addResponse">
              ENVOYER LA REPONSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        v-if="
          !sResponse && checked == this.$store.getters['Auth/get_selected_request'].createdBy.id
        "
      >
        <v-card style="height:62em">
          <v-card-title>
            <h4>
              <!--Aperçu  de la demande d’éclaircissements-->
              Aperçu de la
              {{ this.$store.getters["Auth/get_selected_request"].requestType.description }}
              
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <VueDocPreview
                v-if="
                  this.$store.getters['Auth/get_selected_request'].fileType === 'docx' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'doc' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'xls' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'xlsx' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'XLS' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'XLSX'
                "
                style="height:62em;width:100%"
                frameborder="0"
                :value="this.$store.getters['Auth/get_selected_request'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_request'].fileType === 'pdf'"
                :src="this.$store.getters['Auth/get_selected_request'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="!sResponse && profile == 3">
        <v-card style="height:62em">
          <v-card-title>
            <h4>
              Aperçu de la
              {{ this.$store.getters["Auth/get_selected_request"].requestType.description }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <VueDocPreview
                v-if="
                  this.$store.getters['Auth/get_selected_request'].fileType === 'docx' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'doc' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'xls' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'xlsx' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'XLS' ||
                    this.$store.getters['Auth/get_selected_request'].fileType === 'XLSX'
                "
                style="height:62em;width:100%"
                frameborder="0"
                :value="this.$store.getters['Auth/get_selected_request'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_request'].fileType === 'pdf'"
                :src="this.$store.getters['Auth/get_selected_request'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="sResponse">
        <v-card>
          <v-card-title>
            <h4>{{ Tdr_Ob.object }}</h4>
            <v-spacer></v-spacer>
            <h6>Date : {{ Tdr_Ob.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}</h6>
          </v-card-title>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              Tdr_Ob.fileType == 'doc' ||
                Tdr_Ob.fileType == 'docx' ||
                Tdr_Ob.fileType == 'xls' ||
                Tdr_Ob.fileType == 'xlsx' ||
                Tdr_Ob.fileType == 'XLS' ||
                Tdr_Ob.fileType == 'XLSX'
            "
            :value="Tdr_Ob.fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="Tdr_Ob.fileType == 'pdf'"
                allow="true"
                :src="Tdr_Ob.fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueDocPreview from "vue-doc-preview";
import { mapActions } from "vuex";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    Tdr_Ob: "",
    checked: 0,
    sResponse: false,
    filtered: [],
    response: {
      objet: "",
      content: "",
      file: []
    },
    addi: {
      objet: "",
      publi_date: ""
    },
    ppm: [],
    additif: false,
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.checked = Virt.id;
    this.user_data = Virt;
    this.$store.dispatch("Auth/getRequest", this.id);
    this.$store.dispatch("Auth/loadDocRequest");
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD"
    }),
    clearField() {
      this.response.objet = "";
      this.response.content = "";
      this.response.file = [];
    },
    getFiltered(id) {
      this.filtered = this.$store.getters["Auth/get_selected_request"].responseList.filter(function(
        value,
        index,
        arr
      ) {
        return value.id == id;
      });
    },
    async showResponse(id) {
      let td = id;
      await this.getFiltered(td);
      this.Tdr_Ob = this.filtered[0];
      this.sResponse = !this.sResponse;
    },
    addResponse() {
      const data = new FormData();
      data.append("object", this.response.objet);
      data.append("content", this.response.content);
      data.append("request", this.id);
      data.append("file", this.response.file[0]);
      this.$store.dispatch("Auth/addResponseToRequest", data);
      this.clearField();
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
